"use client";

import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { DropdownMenuItem } from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import ReferralLinkCopy from "./ReferralLinkCopy";
function isMobileOrTablet() {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}
const ShareButton = ({
  shareUrl,
  trigger
}: {
  shareUrl: string;
  trigger?: React.ReactNode;
}) => {
  const shareOnTwitter = () => {
    const url = encodeURIComponent(shareUrl);
    const text = encodeURIComponent(`Check out this review on ${document.title}`);
    const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
    const width = 600;
    const height = 400;

    // Calculate the center position of the screen for the popup window
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const features = `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`;
    window.open(twitterUrl, "twitterShare", features);
  };
  const shareOnWhatsApp = () => {
    const text = encodeURIComponent(`Check this out: ${shareUrl}`);
    const whatsappUrl = `https://${isMobileOrTablet() ? "api" : "web"}.whatsapp.com/send?text=${text}`;
    const width = 600;
    const height = 400;

    // Calculate the center position of the screen for the popup window
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const features = `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`;
    window.open(whatsappUrl, "whatsappShare", features);
  };
  return <Dialog data-sentry-element="Dialog" data-sentry-component="ShareButton" data-sentry-source-file="ShareButton.tsx">
      <DialogTrigger asChild data-sentry-element="DialogTrigger" data-sentry-source-file="ShareButton.tsx">
        {trigger ? trigger : <DropdownMenuItem onSelect={e => e.preventDefault()} className="hover:bg-[#26273b]">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5 16.5C12.875 16.5 12.3438 16.2812 11.9062 15.8438C11.4688 15.4062 11.25 14.875 11.25 14.25C11.25 14.1625 11.2562 14.0717 11.2687 13.9777C11.2812 13.8837 11.3 13.7995 11.325 13.725L6.0375 10.65C5.825 10.8375 5.5875 10.9845 5.325 11.091C5.0625 11.1975 4.7875 11.2505 4.5 11.25C3.875 11.25 3.34375 11.0312 2.90625 10.5938C2.46875 10.1562 2.25 9.625 2.25 9C2.25 8.375 2.46875 7.84375 2.90625 7.40625C3.34375 6.96875 3.875 6.75 4.5 6.75C4.7875 6.75 5.0625 6.80325 5.325 6.90975C5.5875 7.01625 5.825 7.163 6.0375 7.35L11.325 4.275C11.3 4.2 11.2812 4.11575 11.2687 4.02225C11.2562 3.92875 11.25 3.838 11.25 3.75C11.25 3.125 11.4688 2.59375 11.9062 2.15625C12.3438 1.71875 12.875 1.5 13.5 1.5C14.125 1.5 14.6562 1.71875 15.0938 2.15625C15.5312 2.59375 15.75 3.125 15.75 3.75C15.75 4.375 15.5312 4.90625 15.0938 5.34375C14.6562 5.78125 14.125 6 13.5 6C13.2125 6 12.9375 5.947 12.675 5.841C12.4125 5.735 12.175 5.588 11.9625 5.4L6.675 8.475C6.7 8.55 6.71875 8.6345 6.73125 8.7285C6.74375 8.8225 6.75 8.913 6.75 9C6.75 9.087 6.74375 9.17775 6.73125 9.27225C6.71875 9.36675 6.7 9.451 6.675 9.525L11.9625 12.6C12.175 12.4125 12.4125 12.2657 12.675 12.1597C12.9375 12.0537 13.2125 12.0005 13.5 12C14.125 12 14.6562 12.2188 15.0938 12.6562C15.5312 13.0938 15.75 13.625 15.75 14.25C15.75 14.875 15.5312 15.4062 15.0938 15.8438C14.6562 16.2812 14.125 16.5 13.5 16.5Z" fill="white" />
            </svg>
            Share review
          </DropdownMenuItem>}
      </DialogTrigger>
      <DialogContent className="max-w-[480px] gap-6 px-10 pt-8 pb-6 border-none rounded-[16px] bg-[#10111e]" data-sentry-element="DialogContent" data-sentry-source-file="ShareButton.tsx">
        <DialogHeader className="flex flex-col items-center gap-6" data-sentry-element="DialogHeader" data-sentry-source-file="ShareButton.tsx">
          <DialogTitle className="text-center text-2xl text-white font-semibold" data-sentry-element="DialogTitle" data-sentry-source-file="ShareButton.tsx">
            Share review
          </DialogTitle>
          <div className="flex gap-5 items-center">
            <Button variant="link" onClick={shareOnTwitter} className="p-[17px] border-[#bdbdbd]/10 bg-[#161724] border rounded-[50%] size-[54px]" data-sentry-element="Button" data-sentry-source-file="ShareButton.tsx">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ShareButton.tsx">
                <rect width="20" height="20" transform="translate(0 0.75)" fill="#151828" data-sentry-element="rect" data-sentry-source-file="ShareButton.tsx" />
                <path d="M15.2716 2.33643H18.0828L11.9411 9.356L19.1663 18.9081H13.509L9.07804 13.1148L4.00796 18.9081H1.19503L7.7642 11.3998L0.833008 2.33643H6.63393L10.6392 7.6317L15.2716 2.33643ZM14.2849 17.2254H15.8427L5.7875 3.9307H4.11589L14.2849 17.2254Z" fill="white" data-sentry-element="path" data-sentry-source-file="ShareButton.tsx" />
              </svg>
            </Button>
            <Button variant="link" onClick={shareOnWhatsApp} className="p-[17px] border-[#bdbdbd]/10 bg-[#161724] border rounded-[50%] size-[54px]" data-sentry-element="Button" data-sentry-source-file="ShareButton.tsx">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ShareButton.tsx">
                <rect width="20" height="20" transform="translate(0 0.25)" fill="#151828" data-sentry-element="rect" data-sentry-source-file="ShareButton.tsx" />
                <path d="M0 20.25L1.40583 15.1141C0.538332 13.6108 0.0824998 11.9066 0.0833332 10.1591C0.0858331 4.69582 4.53166 0.25 9.99414 0.25C12.645 0.250833 15.1333 1.28333 17.005 3.15666C18.8758 5.02999 19.9058 7.51998 19.905 10.1683C19.9025 15.6325 15.4566 20.0783 9.99414 20.0783C8.33581 20.0775 6.70165 19.6616 5.25415 18.8716L0 20.25ZM5.49749 17.0775C6.89415 17.9066 8.22748 18.4033 9.99081 18.4041C14.5308 18.4041 18.2291 14.7091 18.2316 10.1666C18.2333 5.61499 14.5525 1.925 9.99748 1.92333C5.45415 1.92333 1.75833 5.61832 1.75666 10.16C1.75583 12.0141 2.29916 13.4025 3.21166 14.855L2.37916 17.895L5.49749 17.0775ZM14.9866 12.5241C14.925 12.4208 14.76 12.3591 14.5116 12.235C14.2641 12.1108 13.0466 11.5116 12.8191 11.4291C12.5925 11.3466 12.4275 11.305 12.2616 11.5533C12.0966 11.8008 11.6216 12.3591 11.4775 12.5241C11.3333 12.6891 11.1883 12.71 10.9408 12.5858C10.6933 12.4616 9.89498 12.2008 8.94915 11.3566C8.21331 10.7 7.71582 9.88915 7.57165 9.64081C7.42748 9.39331 7.55665 9.25915 7.67998 9.13581C7.79165 9.02498 7.92748 8.84665 8.05165 8.70165C8.17748 8.55832 8.21831 8.45498 8.30165 8.28915C8.38415 8.12415 8.34331 7.97915 8.28082 7.85498C8.21832 7.73165 7.72332 6.51249 7.51748 6.01665C7.31582 5.53416 7.11165 5.59915 6.95998 5.59165L6.48499 5.58332C6.31999 5.58332 6.05165 5.64499 5.82499 5.89332C5.59832 6.14165 4.95832 6.73999 4.95832 7.95915C4.95832 9.17831 5.84582 10.3558 5.96915 10.5208C6.09332 10.6858 7.71498 13.1875 10.1991 14.26C10.79 14.515 11.2516 14.6675 11.6108 14.7816C12.2041 14.97 12.7441 14.9433 13.1708 14.88C13.6466 14.8091 14.6358 14.2808 14.8425 13.7025C15.0491 13.1233 15.0491 12.6275 14.9866 12.5241Z" fill="white" data-sentry-element="path" data-sentry-source-file="ShareButton.tsx" />
              </svg>
            </Button>
          </div>
        </DialogHeader>

        <div className="flex max-w-[42%] items-center justifycenter">
          <Separator className="bg-[#bdbdbd]/10" data-sentry-element="Separator" data-sentry-source-file="ShareButton.tsx" />
          <div className="text-white/40 px-6">or</div>
          <Separator className="bg-[#bdbdbd]/10" data-sentry-element="Separator" data-sentry-source-file="ShareButton.tsx" />
        </div>

        <ReferralLinkCopy link={shareUrl} label="Share this link" data-sentry-element="ReferralLinkCopy" data-sentry-source-file="ShareButton.tsx" />
      </DialogContent>
    </Dialog>;
};
export default ShareButton;