"use client";

import { useResponsive } from "@/hooks/useResponsive";
import { cn } from "@/lib/utils";
import { useState } from "react";
const ReadMoreText = ({
  text,
  mobileLength = 330,
  desktopLength = 794,
  className
}: {
  text: string;
  mobileLength?: number;
  desktopLength?: number;
  className?: string;
}) => {
  const {
    isMobile
  } = useResponsive();
  const [displayLength, setDisplayLength] = useState(() => isMobile ? mobileLength : desktopLength);
  const handleExpand = () => {
    setDisplayLength(text.length);
  };

  // const processedText = text.replace(/\n{3,}/g, "\n\n")
  if (!text) return null;
  return <p className={cn("text-base whitespace-pre-line font-medium text-[#f4f4f4]", className)} data-sentry-component="ReadMoreText" data-sentry-source-file="ReadMoreText.tsx">
      {text.length > displayLength ? <>
          {text.slice(0, displayLength)}
          <span>...</span>
          <span onClick={handleExpand} className="cursor-pointer underline">
            Read More
          </span>
        </> : text}
    </p>;
};
export default ReadMoreText;