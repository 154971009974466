import { create } from "zustand"

interface UIState {
  showNavbar: boolean
  setShowNavbar: (showNavbar: boolean) => void
  isSkeletonLoading: boolean
  setIsSkeletonLoading: (isSkeletonLoading: boolean) => void
}

const useUIStore = create<UIState>((set) => ({
  showNavbar: true,
  setShowNavbar: (showNavbar) => set({ showNavbar }),
  isSkeletonLoading: false,
  setIsSkeletonLoading: (isSkeletonLoading) => set({ isSkeletonLoading }),
}))

export default useUIStore
